import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  FormLabel,
  InputAdornment,
  FormControl,
  OutlinedInput,
} from "@material-ui/core";

const styles = (theme) => ({
  formLabel: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    color: "#536471",
    display: "block",
    marginBottom: 4,
  },
  outlineInput: {
    paddingRight: 8,
    height: 36,
    minWidth: 300,
    width: "100%",
    zIndex: 0,
    fontSize: 13,
    fontWeight: 400,
    backgroundColor: "#fff",
    fontFamily: "Nunito Sans",
  },
  txtField: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "500",
    color: "#08001D",
    "@media only screen and (max-width: 720px)": {
      borderBottom: "1px solid black",
    },
  },
  underline: {
    color: "black",
    "&::after": {
      transition: "none",
      border: "none",
    },
  },
  inputAdornment: {
    backgroundColor: "#F0F4FA",
    color: "#536471",
    "& .MuiTypography-colorTextSecondary-287": {
      color: "red",
    },
  },
  // fieldText: {
  //   top: 0,
  // },
});

class CustomTextField extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <>
        {this.props.label && (
          <FormLabel className={classes.formLabel}>
            {this.props.label}
          </FormLabel>
        )}
        <FormControl fullWidth={this.props.fullWidth} variant="outlined">
          <OutlinedInput
            startAdornment={
              <InputAdornment
                position="start"
                onClick={(e) => this.props.clickIcon(e)}
              >
                {this.props.unit}
              </InputAdornment>
            }
            disabled={this.props.disabled}
            onFocus={(e) => this.props.onFocus(e)}
            type={this.props.type}
            defaultValue={this.props.value}
            placeholder={this.props.placeholder}
            InputProps={{
              autoComplete: "new-password",
              autoFocus: false,
              form: {
                autocomplete: "off",
              },
              readOnly: this.props.disabled,
              classes: {
                input: classes.txtField,
                underline: classes.underline,
                adornedEnd: classes.inputAdornment,
              },
            }}
            id={this.props.gtmId}
            onChange={(e) => this.props.handleChange(e)}
            className={classes.outlineInput}
            style={this.props.style}
          />{" "}
        </FormControl>
      </>
    );
  }
}

CustomTextField.defaultProps = {
  gtmId: "gtmId",
  type: "string",
  onFocus: () => {},
  clickIcon: () => {},
  BoxHeight: 36,
  fullWidth: true,
};

export default withStyles(styles)(CustomTextField);
