import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Modal,
  Box,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import styles from "./dashboard.style";
import CustomTextField from "../customStyle/textfield";
import "react-circular-progressbar/dist/styles.css";
import DataGrid from "../../components/dataGrid";
import Header from "../../components/header";
import { GiTwoCoins } from "react-icons/gi";
import { allRequestHandler } from "../../api";
import { URLS } from "../../constants/index";
import Spinner from "../../views/customStyle/spinner";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ChatBot from "../../assets/chatBot.gif";
import WhatsappCode from "../../assets/scanWhatsapp.jpeg";
const Dashboard = ({ classes }) => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState();
  const [openShareModal, setOpenShareModal] = useState();
  const [formLayout, setFormLayout] = useState(true);
  const [assetIns, setAssetIns] = useState(null);
  const [assetClass, setAssetClass] = useState(null);
  const [assetInstituition, setAssetInstituition] = useState(null);
  const [householdAssets, setHouseholdAssets] = useState([]);
  const [dependantsInfo, setDependantsInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addInstitution, setAddInstituition] = useState([]);
  const [dependants, setDependats] = useState([
    {
      id: Math.random(),
      dependent_name: "",
      relationship: "",
      phone: "",
    },
  ]);

  const [addAssetLoader, setAddAssetLoader] = useState(true);

  const [selectedAssetClass, setSelectedAssetClass] = useState(null);

  const [selectedAssetInstrument, setSelectedAssetInstrument] = useState(null);

  const [selectedInstituition, setSelectedInstituition] = useState([]);

  const [assetName, setAssetName] = useState("");

  const [assetLocation, setAssetLocation] = useState("");

  const [isShowChatBox, setIsShowChatBox] = useState(false);

  const handleOpen = () => setOpenModal(true);

  const handleClose = () => {
    setSelectedAssetClass(null);

    setSelectedAssetInstrument(null);

    setSelectedInstituition([]);

    setAssetName("");

    setAddInstituition([]);
    setAssetLocation("");
    setOpenModal(false);
  };

  const handleCloseShareModal = () => {
    setOpenShareModal(false);
    setDependats([
      {
        id: Math.random(),
        dependent_name: "",
        relationship: "",
        phone: "",
      },
    ]);
  };

  const onAddDependant = async () => {
    const addedDependants = dependants?.map(({ id, ...rest }) => {
      return rest;
    });

    let getToken = await allRequestHandler({
      requestType: "POST",

      requestUrl: URLS.DEPENDANTS,

      requestData: addedDependants,
    });

    handleCloseShareModal();

    let getDependants = await allRequestHandler({
      requestType: "GET",

      requestUrl: URLS.DEPENDANTS,
    });

    setDependantsInfo(getDependants);
  };

  const addHouseholdAsset = async () => {
    let allInstitution = [...addInstitution, ...selectedInstituition];

    let data = allInstitution.map((item) => {
      return {
        "instrumentname.id": selectedAssetInstrument.id,

        "instrumentname.name": selectedAssetInstrument.name,

        "institution.id": assetInstituition.find((it) => it.id === item)?.id
          ? assetInstituition.find((it) => it.id === item).id
          : null,

        "institution.name": assetInstituition.find((it) => it.id === item)?.name
          ? assetInstituition.find((it) => it.id === item).name
          : item.institution,

        "assetclass.id": selectedAssetClass.id,

        desc: assetName,

        location: assetLocation,

        metadata: null,
      };
    });

    let addAsset = await allRequestHandler({
      requestType: "POST",

      requestUrl: URLS.HOUSEHOLDASSETS,

      requestData: data,
    });

    handleClose();

    let getAssets = await allRequestHandler({
      requestType: "GET",

      requestUrl: URLS.HOUSEHOLDASSETS,
    });

    setHouseholdAssets(getAssets);
  };

  useEffect(async () => {
    setIsLoading(true);

    let getAssets = await allRequestHandler({
      requestType: "GET",

      requestUrl: URLS.HOUSEHOLDASSETS,
    });

    let getDependants = await allRequestHandler({
      requestType: "GET",

      requestUrl: URLS.DEPENDANTS,
    });

    let getAssetClasses = await allRequestHandler({
      requestType: "GET",

      requestUrl: URLS.ASSET_CLASS,
    });

    let getAssetInstituitions = await allRequestHandler({
      requestType: "GET",

      requestUrl: URLS.INSTITUITION,
    });

    setAssetInstituition(getAssetInstituitions);

    if (getAssets?.status == 401 || getAssetClasses?.status == 401) {
      history.push("/login");
    }

    setIsLoading(false);

    setAssetClass(getAssetClasses);

    setHouseholdAssets(getAssets);

    setDependantsInfo(getDependants);
  }, []);

  const addAssetColumns = [
    { field: "id", headerName: "ID", width: 110 },

    {
      field: "class",

      headerName: "Class",

      width: 250,

      editable: false,
    },

    {
      field: "instrument",

      headerName: "Instrument",

      width: 250,

      editable: false,
    },

    {
      field: "institution",

      headerName: "Instituition",

      width: 250,

      editable: false,
    },
    {
      field: "location",

      headerName: "Location",

      width: 200,

      editable: false,
    },
  ];

  const addAssetRows =
    (householdAssets.length > 0 &&
      householdAssets?.map((item, idx) => {
        return {
          id: idx + 1,

          class: item?.assetinstrumentsv1?.assetclasses?.name ?? "-",

          instrument: item?.assetinstrumentsv1?.instrumentnames?.name ?? "-",

          institution: item?.assetinstrumentsv1?.institutions?.name ?? "-",

          location: item?.location ?? "-",
        };
      })) ||
    [];

  const relationsColumns = [
    { field: "id", headerName: "ID", width: 110 },

    {
      field: "name",

      headerName: "Name",

      width: 250,

      editable: true,
    },

    {
      field: "relation",

      headerName: "Relation",

      width: 250,

      editable: true,
    },

    {
      field: "contact",

      headerName: "Contact",

      type: "number",

      width: 200,

      editable: true,
    },
    {
      field: "action",

      headerName: "Action",

      width: 200,

      editable: false,

      renderCell: (params) => (
        <Tooltip title="Coming Soon">
          <Button
            variant="contained"
            className="!bg-primary-600 !text-white !font-medium !px-8"
            style={{ cursor: "not-allowed" }}
            size="small"
          >
            Notify Member
          </Button>
        </Tooltip>
      ),
    },
  ];

  const filteredInstitutions =
    assetInstituition?.filter((u) =>
      selectedInstituition?.includes(u?.id ?? "")
    ) ?? [];

  const relationsRows =
    dependantsInfo.length > 0 &&
    dependantsInfo?.map((item, idx) => {
      return {
        id: idx + 1,
        name: item?.dependent_name,
        relation: item?.relationship,
        contact: item?.phone,
      };
    });

  return (
    <>
      <Header showMenu={true} />

      <Grid className="bg-secondary-gray-25 h-screen">
        <Grid className={classes.outerContainer}>
          <Grid className="flex justify-between">
            <p className="mx-2 text-2xl uppercase">Your Household assets</p>

            <div className="gap-5 flex">
              <div className="relative">
                <div
                  className="absolute text-sm text-white bg-primary-800 border border-gray-300 p-[2px]  rounded z-50 top-1 -left-5 -rotate-45"
                  style={{
                    fontSize: "10px",
                    fontWeight: 500,
                    lineHeight: "12px",
                  }}
                >
                  Coming soon!
                </div>
                <Button
                  variant="outlined"
                  className="!bg-primary-600 !text-white !font-medium !px-8"
                  disableElevation
                >
                  Calculate Asset Sanity
                </Button>
              </div>
              <Button
                variant="outlined"
                className="!bg-primary-700 !text-white !font-medium"
                disableElevation
                onClick={handleOpen}
              >
                Add New Asset
              </Button>

              <Button
                variant="outlined"
                disableElevation
                className="!border-sm !border-primary-700 !text-primary-700 !font-medium"
                onClick={() => setOpenShareModal(true)}
              >
                Share
              </Button>
            </div>
          </Grid>
        </Grid>

        {isLoading ? (
          <Grid className="min-h-[500px]">
            <Spinner />
          </Grid>
        ) : (
          <>
            <Grid className={classes.dataGridContainer}>
              <Grid style={{ display: "flex", alignItems: "center" }}>
                <GiTwoCoins fontSize={30} color="goldenrod" />

                <h3 className="my-[10px] mx-[5px] text-xl font-medium">
                  Your Assets
                </h3>
              </Grid>

              <DataGrid rows={addAssetRows} columns={addAssetColumns} />
            </Grid>

            <Grid className={classes.dataGridContainer}>
              <Grid className="flex items-center">
                <h3 className="my-[10px] mx-[5px] text-xl font-medium">
                  Your Relations
                </h3>
              </Grid>

              <DataGrid rows={relationsRows} columns={relationsColumns} />
            </Grid>

            <div
              className={classes.botWrapper}
              onClick={() => setIsShowChatBox(() => !isShowChatBox)}
            >
              <Tooltip title="Chat With Us!">
                <img src={ChatBot} className={classes.botStyle} alt="chatBot" />
              </Tooltip>

              {isShowChatBox && (
                <div className={classes.chatBox}>
                  <div className={classes.chatBoxHeader}>
                    <span>AssetSathi</span>
                    <span
                      className={classes.chatBoxHeaderClose}
                      onClick={() => setIsShowChatBox(false)}
                    >
                      X
                    </span>
                  </div>
                  <div className={classes.chatBoxBody}>
                    <h5
                      style={{
                        fontSize: "1rem",
                        fontWeight: 800,
                        color: "#25D366",
                      }}
                    >
                      Chat With Us!
                    </h5>
                    <p
                      style={{
                        fontSize: "0.875rem",
                        color: "rgb(116 111 111)",
                        margin: 0,
                      }}
                    >
                      Connect on WhatsApp
                    </p>
                    <img
                      src={WhatsappCode}
                      alt="QR Code"
                      style={{
                        height: "11rem",
                        margin: "auto",
                        padding: "1rem",
                      }}
                    />
                    <p
                      style={{
                        fontWeight: 800,
                        color: "#25D366",
                        margin: 0,
                        fontSize: "0.875rem",
                      }}
                    >
                      +1(607) 424-6922
                    </p>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalContainer}>
            <Grid className="flex space-between border-b-2 min-w-[500px]">
              <Typography className="!text-lg text-primary-900 !font-semibold uppercase p-3">
                Add Asset
              </Typography>
            </Grid>

            <Grid>
              <form>
                <div style={{ margin: 20 }}>
                  <Typography className={classes.labelName}>
                    Select Class
                  </Typography>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedAssetClass?.name}
                    label="Asset Class"
                    classes={{
                      root: classes.searchByInput,
                    }}
                    style={{ width: "100%" }}
                    onChange={async (e) => {
                      let getAssetIns = await allRequestHandler({
                        requestType: "GET",

                        requestUrl: `${URLS.ASSET_INSTRUMENT}?assetclass_id=${[
                          e.target.value.id,
                        ]}`,
                      });
                      setAssetIns(getAssetIns);
                      setSelectedAssetClass(e.target.value);
                    }}
                  >
                    {assetClass?.map((item) => (
                      <MenuItem
                        value={item}
                        classes={{
                          root: classes.searchByInput,
                        }}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div style={{ margin: 20 }}>
                  <Typography className={classes.labelName}>
                    Select Asset Instrument
                  </Typography>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedAssetInstrument}
                    label="Instituition"
                    classes={{
                      root: classes.searchByInput,
                    }}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setSelectedAssetInstrument(e.target.value);
                    }}
                  >
                    {assetIns?.map((item) => (
                      <MenuItem
                        value={item}
                        classes={{
                          root: classes.searchByInput,
                        }}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div style={{ margin: 20 }}>
                  <Typography className={classes.labelName}>
                    Select Financial Instituition
                  </Typography>

                  <Autocomplete
                    value={filteredInstitutions}
                    limitTags={5}
                    multiple
                    id="size-small-outlined-multi"
                    className={`h-[44px] flex-grow hover:bg-red overflow-hidden !outline-0 !disabled:opacity-30 `}
                    size="small"
                    options={assetInstituition}
                    disableCloseOnSelect
                    onChange={(e, value) => {
                      setSelectedInstituition(value.map((it) => it.id));
                    }}
                    getOptionLabel={(option) => option?.name ?? ""}
                    renderInput={(params) => (
                      <div>
                        <TextField
                          {...params}
                          placeholder="Select Instituitions"
                          className=""
                        />
                      </div>
                    )}
                    renderOption={(props, option, state) => {
                      return (
                        props.name && (
                          <li
                            className={`m-[6px] py-[10px] text-gray-700 text-sm font-medium pr-[10px] ${
                              state?.selected ? "bg-gray-50" : ""
                            }`}
                          >
                            {state?.selected && (
                              <div className="flex w-full justify-between">
                                {props.name}
                              </div>
                            )}
                            {!state?.selected && <div>{props.name}</div>}
                          </li>
                        )
                      );
                    }}
                    sx={{
                      ".MuiInputBase-root": {
                        display: "flex",
                        flexWrap: "nowrap",
                        overflow: "hidden",
                        borderRadius: "8px",
                        outline: "none",
                      },
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        // eslint-disable-next-line react/jsx-key
                        <Chip
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                          className="!rounded-sm !bg-transparent !border-gray-300 !border-sm !border-solid !border-1 !mr-[4px]"
                        />
                      ))
                    }
                  />
                </div>
                <div className="my-[20px] mx-4 ">
                  {addInstitution?.map((item) => (
                    <div className="flex w-full items-center gap-2.5">
                      <div className="my-[20px] w-3/4">
                        <Typography className={classes.labelName}>
                          Add new institution name
                        </Typography>

                        <CustomTextField
                          id="insti"
                          name="insti"
                          autoComplete="off"
                          autoFocus
                          value={item.institution}
                          handleChange={(e) =>
                            (item.institution = e.target.value)
                          }
                          placeholder="Enter financial institution"
                          BoxHeight={"38px"}
                        />
                      </div>
                      <div className="w-6 h-6 min-w-[24px] min-h-[24px]  flex justify-center items-center bg-primary-700 rounded-full mr-2 mt-8 cursor-pointer">
                        <div
                          onClick={() =>
                            setAddInstituition(
                              addInstitution.filter(
                                (data) => item.id !== data.id
                              )
                            )
                          }
                        >
                          <svg
                            width={"16"}
                            height={"16"}
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11 5L5 11M5 5L11 11"
                              stroke={"#fff"}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className="my-[20px] mx-4 flex justify-end font-semibold text-sm cursor-pointer text-primary-500"
                  onClick={() =>
                    setAddInstituition([
                      ...addInstitution,
                      { id: Math.random(), institution: "" },
                    ])
                  }
                >
                  + Add New Instituition
                </div>

                <div style={{ margin: 20 }}>
                  <Typography className={classes.labelName}>
                    Description
                  </Typography>

                  <CustomTextField
                    id="name"
                    name="name"
                    autoComplete="off"
                    autoFocus
                    value={assetName}
                    handleChange={(e) => setAssetName(e.target.value)}
                    placeholder="Enter description"
                    BoxHeight={"38px"}
                  />
                </div>

                <div style={{ margin: 20 }}>
                  <Typography className={classes.labelName}>
                    Location
                  </Typography>

                  <CustomTextField
                    id="location"
                    name="location"
                    placeholder="Enter location"
                    value={assetLocation}
                    handleChange={(e) => setAssetLocation(e.target.value)}
                    autoFocus
                    BoxHeight={"38px"}
                  />
                </div>

                <div
                  className={
                    "w-full bg-white border-t-2 h-20 right-0 shadow px-4 pb-4 pt-6 flex items-center justify-end"
                  }
                >
                  <Button
                    className={
                      "cursor-pointer  !bg-white !text-black !px-[16px] !py-[10px] !text-sm !font-semibold !border-sm !rounded-lg !border-solid !normal-case !border-gray-300 !ml-[8px] !w-[120px] disabled:opacity-50"
                    }
                    style={{
                      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    className={
                      "cursor-pointer  !bg-primary-600 !text-white !px-[16px] !py-[10px] !text-sm !font-semibold !border-sm !rounded-lg !border-solid !normal-case !border-gray-300 !ml-[8px] !w-[120px] disabled:opacity-50"
                    }
                    style={{
                      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                    }}
                    onClick={addHouseholdAsset}
                  >
                    Save
                    {/* {addAssetLoader && (

                      <CircularProgress

                        style={{

                          color: "#fff",

                          marginLeft: "40px",

                        }}

                        size={18}

                      />

                    )} */}
                  </Button>

                  {/* <Button

                    className={

                      "cursor-pointer  !bg-primary-600 !text-white !px-[16px] !py-[10px] !text-sm !font-semibold !border-sm !rounded-lg !border-solid !normal-case !border-gray-300 !ml-[8px] !w-[120px] disabled:opacity-50"

                    }

                    style={{

                      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",

                    }}

                    onClick={addHouseholdAsset}

                  >

                    {addAssetLoader && (

                      <CircularProgress

                        style={{

                          color: "#fff",

                          marginLeft: "40px",

                        }}

                        size={18}

                      />

                    )}{" "}

                    Save

                  </Button> */}
                </div>
              </form>
            </Grid>
          </Box>
        </Modal>

        <Modal
          open={openShareModal}
          onClose={handleCloseShareModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalContainer}>
            <Grid className="flex space-between border-b-2">
              <Typography className="!text-lg text-primary-900 !font-medium uppercase p-3">
                Add your dependants
              </Typography>
            </Grid>

            <Grid>
              <form>
                {dependants?.map((item) => (
                  <div className="flex justify-between items-center">
                    <div className="m-[16px]">
                      <Typography className={classes.labelName}>
                        Name
                      </Typography>

                      <CustomTextField
                        id="name"
                        name="name"
                        autoComplete="off"
                        autoFocus
                        placeholder="Enter name"
                        BoxHeight={"38px"}
                        value={item.dependent_name}
                        handleChange={(e) =>
                          (item.dependent_name = e.target.value)
                        }
                      />
                    </div>

                    <div style={{ margin: 20 }}>
                      <Typography className={classes.labelName}>
                        Relation
                      </Typography>

                      <CustomTextField
                        id="location"
                        name="location"
                        placeholder="Enter relation"
                        autoFocus
                        BoxHeight={"38px"}
                        value={item.relationship}
                        handleChange={(e) =>
                          (item.relationship = e.target.value)
                        }
                      />
                    </div>

                    <div style={{ margin: 20 }}>
                      <Typography className={classes.labelName}>
                        Contact
                      </Typography>

                      <CustomTextField
                        id="location"
                        name="location"
                        placeholder="Enter your phone number"
                        autoFocus
                        BoxHeight={"38px"}
                        value={item.phone}
                        handleChange={(e) =>
                          (item.phone = "91" + e.target.value)
                        }
                      />
                    </div>

                    <div
                      className="

                 w-6 h-6 min-w-[24px] min-h-[24px]  flex justify-center items-center bg-primary-700 rounded-full mr-2 mt-8 cursor-pointer"
                    >
                      <div
                        onClick={() =>
                          setDependats(
                            dependants.filter((data) => item.id !== data.id)
                          )
                        }
                      >
                        <svg
                          width={"16"}
                          height={"16"}
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 5L5 11M5 5L11 11"
                            stroke={"#fff"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                ))}

                <div
                  className="cursor-pointer p-[10px] pb[6-px] text-base font-bold leading-6 text-primary-600 mt-2 mr-4 flex justify-end"
                  onClick={() =>
                    setDependats([
                      ...dependants,

                      {
                        id: Math.random(),

                        dependent_name: "",

                        relationship: "",

                        phone: "",
                      },
                    ])
                  }
                >
                  {" "}
                  + Add{" "}
                </div>

                <div
                  className={
                    "w-full bg-white border-t-2 h-20 right-0 shadow px-4 pb-4 pt-6 flex items-center justify-end"
                  }
                >
                  <Button
                    className={
                      "cursor-pointer  !bg-white !text-black !px-[16px] !py-[10px] !text-sm !font-semibold !border-sm !rounded-lg !border-solid !normal-case !border-gray-300 !ml-[8px] !w-[120px] disabled:opacity-50"
                    }
                    style={{
                      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                    }}
                    onClick={handleCloseShareModal}
                  >
                    Cancel
                  </Button>

                  <Button
                    className={
                      "cursor-pointer  !bg-primary-600 !text-white !px-[16px] !py-[10px] !text-sm !font-semibold !border-sm !rounded-lg !border-solid !normal-case !border-gray-300 !ml-[8px] !w-[120px] disabled:opacity-50"
                    }
                    style={{
                      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                    }}
                    onClick={onAddDependant}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </>
  );
};

export default withStyles(styles)(Dashboard);
