import React, { useState, useEffect } from "react";
import {
  Grid,
  Modal,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./cardList.style";
// import { FcMoneyTransfer } from 'react-icons/fc';
import Tick from "../../assets/tick.svg";
import { allRequestHandler } from "../../api";
import { URLS } from "../../constants/index";
import {
  setAssetClass,
  setAssetClasses,
  // setAssetClassInstruments,
  manipulateInstruments,
  getEstimatorValue,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../views/customStyle/spinner";

function CardList(props) {
  const { classes } = props;
  const [open, setOpen] = useState(false);
  const [selectedInstruments, setSelectedInstruments] = useState(null);
  const [currentAssetClass, setCurrentAssetClass] = useState(null);
  const [fetAssetClasses, setFetAssetClasses] = useState([]);
  const [assetClassesLoader, setAssetClassesLoader] = useState(true);
  const [assetInstrumentLoader, setAssetInstrumentLoader] = useState(false);
  const dispatch = useDispatch();

  const selectedAssetClasses = useSelector((state) => state.assetClasses);
  const guessedAssetClasses = useSelector((state) => state.guessedAssetClasses);
  const selectedInstrumentsClasses = useSelector(
    (state) => state.assetInstruments
  );

  const selectAsset = async (name) => {
    console.log(name);
    if (!selectedAssetClasses.includes(name)) {
      setOpen(true);
    }
    setAssetInstrumentLoader(true);
    let assetInstruments = await allRequestHandler({
      requestType: "GET_WITHOUT_CREDS",
      requestUrl: URLS.ASSET_INSTRUMENT + `?assetclass_id=` + [name],
    });
    setSelectedInstruments(assetInstruments);
    setAssetInstrumentLoader(false);
    setCurrentAssetClass(name);
    dispatch(getEstimatorValue());
    dispatch(setAssetClasses(name));
  };

  useEffect(async () => {
    let assetClasses = await allRequestHandler({
      requestType: "GET_WITHOUT_CREDS",
      requestUrl: URLS.ASSET_CLASS,
    });
    setFetAssetClasses(assetClasses);
    setAssetClassesLoader(false);
  }, []);
  const clearSelection = () => {
    dispatch(setAssetClass([]));
    dispatch(getEstimatorValue());
  };
  const handleInstrumentSelection = (ins) => {
    dispatch(manipulateInstruments(ins));
  };

  const handleClose = () => setOpen(false);

  return (
    <Grid>
      <div className={classes.subtitleStyle}>
        Please select the <b>asset classes</b> you own
      </div>
      <Grid
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          maxHeight: "80px",
          margin: "20px 5px",
        }}
      >
        <Grid style={{ display: "flex" }} className={classes.countContainer}>
          <Typography className={classes.clearAll} style={{ fontSize: 20 }}>
            Your guess : <b>{guessedAssetClasses || 0}</b> |
          </Typography>
          <Typography className={classes.clearAll} style={{ fontSize: 20 }}>
            Your selection : <b>{selectedInstrumentsClasses?.length} </b> |
          </Typography>
          <Typography
            className={classes.clearAll}
            onClick={clearSelection}
            style={{ color: "#737373" }}
          >
            Clear All
          </Typography>
        </Grid>
      </Grid>

      <Grid className={classes.cardList}>
        {assetClassesLoader ? (
          <div className="min-h-[300px]">
            <Spinner />
          </div>
        ) : (
          fetAssetClasses &&
          fetAssetClasses?.map((item, index) => (
            <Grid
              key={index}
              item
              className={classes.cardContainer}
              onClick={() => selectAsset(item.id)}
              style={
                selectedAssetClasses.includes(item.id)
                  ? {
                      border: "1.5px solid #6250DE",
                      boxShadow: "4px 9px 1px 0px  #6250DE26",
                    }
                  : {}
              }
            >
              <Grid style={{ display: "flex" }}>
                {selectedAssetClasses.includes(item.id) && (
                  <span style={{ marginRight: 8 }}>
                    <img src={Tick} alt="tick" />
                  </span>
                )}
                <span
                  className={classes.cardTitle}
                  style={
                    selectedAssetClasses.includes(item.id)
                      ? { color: "#6250DE" }
                      : {}
                  }
                >
                  {item.name}
                </span>
              </Grid>
            </Grid>
          ))
        )}
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalContainer}>
          <Typography className={classes.modalTitle}>
            Select your instruments
          </Typography>
          {assetInstrumentLoader ? (
            <div className="min-h-[200px]">
              <Spinner />
            </div>
          ) : (
            <FormGroup style={{ padding: 10 }}>
              {selectedInstruments &&
                selectedInstruments?.map((ins, idx) => (
                  <FormControlLabel
                    key={idx}
                    className={classes.checkbokTitle}
                    control={
                      <Checkbox
                        size="small"
                        value={ins}
                        style={{
                          color: "#6250DE",
                        }}
                        onChange={() => {
                          handleInstrumentSelection(ins);
                        }}
                      />
                    }
                    label={ins.name}
                  />
                ))}
            </FormGroup>
          )}

          <Grid style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Button
              onClick={handleClose}
              style={{
                background: "#49287d",
                color: "#fff",
                fontFamily: "Nunito Sans",
                margin: "0px auto",
                padding: "8px 40px",
                fontWeight: "500",
              }}
            >
              Save
            </Button>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
}
export default withStyles(styles)(CardList);
