import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  withStyles,
  Snackbar,
  Box,
  Modal,
} from "@material-ui/core";
import Illustration from "../../assets/Jan-Business_team_3.jpg";
import OtpInput from "react-otp-input";
import Alert from "@material-ui/lab/Alert";
import CustomTextField from "../customStyle/textfield";
import styles from "./login.style";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { allRequestHandler } from "../../api";
import { URLS } from "../../constants/index";
import { CircularProgress } from "@material-ui/core";
import BulkInsert from "./BulkInsert";

const CustomerLogin = ({ classes }) => {
  const history = useHistory();
  const [handleSnackbar, setHandleSnackbar] = useState(null);

  const [displayMsg, setDisplayMsg] = useState(null);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [open, setOpen] = React.useState(false);
  const [isVerified, setVerified] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [severity, setSeverity] = useState("sucess");
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState("");
  const [showBulkInsert, setShowBulkInsert] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setVerified(true);
  };

  useEffect(() => {
    sessionStorage.setItem("role", "customer");
  }, []);

  const logIn = async (e) => {
    e.preventDefault();
    setOtpLoading(true);
    if (mobile !== "") {
      let getToken = await allRequestHandler({
        requestType: "LOGIN",
        requestUrl: URLS.OTP,
        requestData: {
          phone: 91 + mobile,
        },
      });
      if (getToken?.status == 200) {
        setOtpLoading(false);
        handleOpen();
        localStorage.setItem("loginData", JSON.stringify(getToken));
        sessionStorage.setItem("jwtToken", getToken.data.Token);
      } else {
        let validationMsg = "Otp sent successfully";
        setDisplayMsg(validationMsg);
        setHandleSnackbar(true);
        setSeverity("sucess");
      }
    } else {
      setDisplayMsg("Please enter your mobile number");
      setHandleSnackbar(true);
    }
  };

  const registerUser = async () => {
    let getToken = await allRequestHandler({
      requestType: "POST",
      requestUrl: URLS.REGISTER,
      requestData: {
        city: city,
        state: state,
        dob: dob,
        address: address,
        name: name,
      },
    });
    console.log(getToken);
    if (getToken.message == "profile registered/updated successfully!") {
      //navigate to dashboard
      // history.push('/dashboard')
      setShowBulkInsert(true);
    }
  };

  const verifyOtp = async () => {
    if (otp !== "") {
      setVerifyLoading(true);
      let getToken = await allRequestHandler({
        requestType: "POST",
        requestUrl: URLS.VERIFYOTP,
        requestData: {
          phone: 91 + mobile,
          type: "sms",
          token: otp,
        },
      });
      if (getToken?.access_token) {
        handleClose();
        localStorage.setItem("loginData", JSON.stringify(getToken));
        sessionStorage.setItem("jwtToken", getToken?.access_token);
        if (getToken?.profile) {
          history.push("/dashboard");
        } else {
          setVerifyLoading(false);
        }
      } else {
        setDisplayMsg("");
        setHandleSnackbar(true);
      }
    } else {
      setDisplayMsg("Please enter your OTP");
      setHandleSnackbar(true);
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        open={handleSnackbar}
        onClose={() => setHandleSnackbar(false)}
      >
        <Alert
          elevation={6}
          variant="standard"
          color={severity}
          severity={severity}
        >
          {displayMsg}
        </Alert>
      </Snackbar>

      {!isVerified ? (
        <Grid className={classes.centerContainer}>
          <Grid className={classes.loginBox}>
            <img src={Illustration} height={350} width={400} />
            <Typography className={classes.loginTitle}>
              Log In / Sign Up
            </Typography>
            <Typography className={classes.subtitle}>
              Consolidate your assets and share it with your dependants
            </Typography>
            <Grid className={classes.login}>
              <form onSubmit={(e) => logIn(e)}>
                <div style={{ marginBottom: 20 }}>
                  <Typography className={classes.labelName}>
                    Mobile Number
                  </Typography>
                  <CustomTextField
                    id="email"
                    name="email"
                    unit="+91"
                    type="number"
                    autoComplete="off"
                    autoFocus
                    BoxHeight={"38px"}
                    handleChange={(e) => setMobile(e.target.value)}
                  />
                </div>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.cta}
                  disabled={
                    !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(mobile)
                  }
                >
                  Generate OTP{" "}
                  {otpLoading && (
                    <CircularProgress
                      style={{
                        color: "#fff",
                        marginLeft: "40px",
                      }}
                      size={18}
                    />
                  )}
                </Button>
              </form>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid>
          {showBulkInsert ? (
            <BulkInsert />
          ) : (
            <Grid className={classes.centerContainer}>
              <Grid className={classes.loginBox} style={{ width: "50vw" }}>
                <Typography
                  className={classes.loginTitle}
                  style={{ marginBottom: 20 }}
                >
                  Register
                </Typography>

                <Grid className={classes.login}>
                  <form>
                    <div style={{ marginBottom: 20 }}>
                      <Typography className={classes.labelName}>
                        Name
                      </Typography>
                      <CustomTextField
                        id="name"
                        name="name"
                        type="text"
                        style={{ height: 40 }}
                        placeholder="Enter your name"
                        handleChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div style={{ marginBottom: 20 }}>
                      <Typography className={classes.labelName}>
                        City
                      </Typography>
                      <CustomTextField
                        id="city"
                        name="city"
                        type="text"
                        style={{ height: 40 }}
                        placeholder="Enter your city"
                        handleChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                    <div style={{ marginBottom: 20 }}>
                      <Typography className={classes.labelName}>
                        State
                      </Typography>
                      <CustomTextField
                        id="state"
                        name="state"
                        type="text"
                        style={{ height: 40 }}
                        placeholder="Enter your state"
                        handleChange={(e) => setState(e.target.value)}
                      />
                    </div>
                    <div style={{ marginBottom: 20 }}>
                      <Typography className={classes.labelName}>
                        Date of Birth
                      </Typography>
                      <CustomTextField
                        id="dob"
                        name="dob"
                        style={{ height: 40 }}
                        type="date"
                        placeholder="Enter your date of birth"
                        handleChange={(e) => setDob(e.target.value)}
                      />
                    </div>
                    <div style={{ marginBottom: 20 }}>
                      <Typography className={classes.labelName}>
                        Address
                      </Typography>
                      <CustomTextField
                        id="address"
                        name="address"
                        type="text"
                        style={{ height: 40 }}
                        placeholder="Enter your address in one line"
                        handleChange={(e) => setAddress(e.target.value)}
                      />
                    </div>

                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.cta}
                      onClick={registerUser}
                    >
                      Go to Dashboard
                    </Button>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalContainer}>
          <Typography
            style={{
              marginBottom: 0,
              fontWeight: 400,
              fontSize: 18,
              marginBottom: 0,
              borderBottom: "1px solid #c5c5c5",

              padding: 10,
            }}
          >
            OTP Verification
          </Typography>
          <Grid
            style={{
              padding: 40,
              display: "flex",
              justifyContent: "center",
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <OtpInput
              value={otp}
              onChange={setOtp}
              shouldAutoFocus
              numInputs={6}
              inputStyle={{
                fontSize: 25,
                padding: 10,
                margin: 10,
                width: 45,
                height: 55,
                background: "#f5f5f5",
                border: "0.5px solid #d9d9d9",
                borderRadius: 4,
              }}
              renderInput={(props) => <input {...props} />}
            />
          </Grid>
          <Typography className={classes.subtitle} style={{ marginLeft: 50 }}>
            Didn't receive the OTP ?{" "}
            <b
              style={{
                color: "#ffb578",
                cursor: "pointer",
                textTransform: "uppercase",
                marginLeft: 5,
              }}
              onClick={logIn}
            >
              RESEND
            </b>
          </Typography>
          <Grid style={{ margin: 10 }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.cta}
              onClick={verifyOtp}
              disabled={otp.length < 6}
            >
              Verify and proceed
              {verifyLoading && (
                <CircularProgress
                  style={{
                    color: "#fff",
                    marginLeft: "40px",
                  }}
                  size={18}
                />
              )}
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default withStyles(styles)(CustomerLogin);
