// eslint-disable-next-line import/no-anonymous-default-export
export default {
  outerContainer: {
    height: "auto",
    padding: 20,
  },
  chartContainer: {
    height: "auto",
    padding: 20,
    maxWidth: 500,
    border: "0.2px solid #c4c4c4",
    background: "#fff",
    borderRadius: 5,
    fontFamily: "Nunito Sans",
    margin: 10,
    width: "100%",
  },
  labelName: {
    fontSize: 14,
    fontFamily: "Nunito Sans",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "20px",
    color: "#536471",
    margin: "5px 0",
  },
  typography32: {
    fontSize: 20,
    color: "#26184D",
    fontWeight: 600,
    fontFamily: "Nunito Sans",
    textTransform: "uppercase",
  },
  cta: {
    border: "2px solid #49287d",
    color: "#49287d",
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    lineHeight: "25px",
    transition: "transform 0.25s ease-out",
    "&:hover": {
      border: "2px solid #4a378a",
    },
  },
  modalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    maxHeight: "80vh",
    overflow: "scroll",
    bgcolor: "background.paper",
    borderRadius: "8px",

    boxShadow: 24,
    p: 4,
    background: "#fff",
  },

  chartDesc: {
    width: "80%",
    fontFamily: "Nunito Sans",
    fontSize: 14,
  },
  cursorNotAllowed: {
    cursor: "not-allowed",
  },
  viewBtn: {
    fontSize: 20,
    color: "#26184D",
    fontWeight: 600,
    padding: "10px 20px",
    background: "#f0cdbd",
    borderRadius: 12,
    cursor: "pointer",
    fontFamily: "Nunito Sans",
    background: "rgb(240,205,189)",
    background:
      "linear-gradient(4deg, rgba(240,205,189,0.6898109585631127) 25%, rgba(224,99,43,0.4449930313922444) 99%)",
  },
  cardList: {
    margin: "0 auto",
    display: "grid",
    padding: "20px",
    gridGap: "20px",
    width: "70%",
    gridTemplateColumns: "repeat(3,1fr)",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    background: "#f4f2fa",
    position: "relative",
    minWidth: 220,
    alignItems: "flex-start",
    cusor: "pointer",
    borderRadius: "5px",
    padding: "15px",
    cursor: "pointer",
    margin: "5px",
    transition: "transform 0.25s ease-out",
    "&:hover": {
      // transform: 'scale(1.05)',
      border: "0.2px solid #c4c4c4",
    },
    "&:focus": {
      background: "#ece0ff",
    },
  },
  cardSubtitle: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: 14,
    lineHeight: "40px",
    color: "#737578",
    textTransform: "capitalize",
  },
  dataGridContainer: {
    height: "auto",
    padding: 10,
    maxWidth: "85%",
    border: "0.2px solid #c4c4c4",
    background: "#fff",
    borderRadius: 5,
    margin: "20px auto",
  },
  mainCta: {
    background: "#433794",
    color: "#fff",
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    lineHeight: "25px",
    padding: "5px 12px",
    marginLeft: "8px",
    transition: "transform 0.25s ease-out",
    "&:hover": {
      background: "#4a378a",
    },
  },
  botWrapper: {
    position: "fixed",
    bottom: 0,
    right: 0,
  },
  botStyle: {
    height: "6rem",
    cursor: "pointer",
  },
  chatBox: {
    width: "18rem",
    height: "20rem",
    backgroundColor: "#fff",
    position: "absolute",
    top: "-15rem",
    right: "1rem",
    border: "1px solid #a4a4a4",
    borderRadius: "0.25rem",
  },
  chatBoxHeader: {
    padding: "0.5rem",
    background: "#49287d",
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    fontSize: "0.875rem",
  },
  chatBoxHeaderClose: {
    cursor: "pointer",
    fontWeight: "800",
  },
  chatBoxBody: {
    padding: "1rem",
    textAlign: "center",
  },
};
