import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';

export default function DataTable({rows, columns}) {
  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        sx={{ m: 2 }}
        rows={rows || []}
        columns={columns || []}
        pageSize={5}
        checkboxSelection={false}
        disableSelectionOnClick
      />
    </div>
  );
}
