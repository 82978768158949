import * as types from "./types";

const INITIAL_STATE = {
  assetClasses: [],
  assetInstruments: [],
  estimatorBestValue: 0,
  estimatorWorstValue: 0,
  estimatorBestVisit: 0,
  estimatorWorstVisit: 0,
  guessedAssetClasses: null,
  minimumCost: "INR 15000",
  maximumCost: "2-3% of total worth of transferable assets",
  mainAssetClasses: [
    {
      id: 1,
      name: "Savings Accounts & Fixed Deposits",
      ideal_time: 0.5,
      ideal_visits: 1,
      worst_time: 3,
      worst_visits: 5,
      court_visits: 0,
      instruments: [
        "Bank Savings Accounts",
        "Bank Fixed Deposits",
        "NBFC Fixed Deposits",
      ],
    },
    {
      id: 2,
      name: "Equities",
      ideal_time: 0.5,
      ideal_visits: 0,
      worst_time: 11,
      worst_visits: 5,
      court_visits: 5,
      instruments: ["Stocks", "ESOPS", "RSUs", "ESPP", "Options & Futures"],
    },
    {
      id: 3,
      name: "Funds",
      ideal_time: 0.5,
      ideal_visits: 0,
      worst_time: 9,
      worst_visits: 10,
      court_visits: 5,
      instruments: ["Mutual Funds", "ETFs"],
    },
    {
      id: 4,
      name: "Insurances",
      ideal_time: 1,
      ideal_visits: 1,
      worst_time: 3,
      worst_visits: 3,
      court_visits: 0,
      instruments: [
        "Term Insurance",
        "Life Insurance",
        "Group Term Insurance",
        "Home Loan Linked Life Insurance",
        "Savings Account Linked Life Insurance",
        "Credit Card Linked Life Insurance",
        "EPF Linked EDLI Insurance",
        "Home Insurance",
        "Automobile Insurance",
        "Travel Insurances (Micro Insurance)",
      ],
    },
    {
      id: 5,
      name: "Small Savings Schemes",
      ideal_time: 0.5,
      ideal_visits: 1,
      worst_time: 7,
      worst_visits: 2,
      court_visits: 5,
      instruments: [
        "Public Provident Fund (PPF)",
        "National Savings Certificate (NSC)",
        "Post Office Time Deposit (TD)",
        "Senior Citizens Savings Scheme (SCSS)",
        "Sukanya Samriddhi Yojana (SSY)",
        "Post Office Monthly Income Scheme (POMIS)",
        "Post Office Savings Deposit",
        "Kisan Vikas Patra (KVP)",
      ],
    },
    {
      id: 6,
      name: "Pension Schemes",
      ideal_time: 1,
      ideal_visits: 1,
      worst_time: 9,
      worst_visits: 3,
      court_visits: 5,
      instruments: [
        "National Pension Schemes (NPS)",
        "Employee Provident Fund (EPF)",
        "Atal Pension Yojana (APY)",
        "Pradhan Mantri Shram Yogi Maan-Dhan (PM-SYM)",
      ],
    },
    {
      id: 7,
      name: "Bonds & Debentures",
      ideal_time: 0.5,
      ideal_visits: 0,
      worst_time: 11,
      worst_visits: 3,
      court_visits: 5,
      instruments: [
        "Government of India Dated Bonds",
        "Treasury Bills",
        "SDL - State Development Loans",
        "Sovereign Gold Bonds",
        "Corporate Bonds & NCDs",
      ],
    },
    {
      id: 8,
      name: "Real Estate",
      ideal_time: 1,
      ideal_visits: 2,
      worst_time: 11,
      worst_visits: 2,
      court_visits: 5,
      instruments: [
        "Residential",
        "Commercial",
        "Fractional Real Estate",
        "REITs",
      ],
    },
    {
      id: 9,
      name: "Hard Assets",
      ideal_time: 0.5,
      ideal_visits: 1,
      worst_time: 2,
      worst_visits: 3,
      court_visits: 0,
      instruments: ["Bank Lockers", "Jewellery", "Automobiles"],
    },
    {
      id: 10,
      name: "Lending",
      ideal_time: 0.5,
      ideal_visits: 1,
      worst_time: 2,
      worst_visits: 2,
      court_visits: 0,
      instruments: [
        "Lending through platforms (e.g 12% club)",
        "Loans to Friends & Family",
      ],
    },
    {
      id: 11,
      name: "Chit Funds",
      ideal_time: 0.5,
      ideal_visits: 1,
      worst_time: 1,
      worst_visits: 3,
      court_visits: 0,
      instruments: ["Chit Fund"],
    },
    {
      id: 12,
      name: "Crypto",
      ideal_time: 1,
      ideal_visits: 0,
      worst_time: 2,
      worst_visits: 3,
      court_visits: 0,
      instruments: ["Cryptocurrencies", "NFT"],
    },
    {
      id: 13,
      name: "Miscellaneous",
      ideal_time: 0.5,
      ideal_visits: 0,
      worst_time: 1,
      worst_visits: 1,
      court_visits: 0,
      instruments: ["Misc 1", "Misc 2", "Misc 3"],
    },
  ],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.default.SET_ASSET_CLASS:
      return {
        ...state,
        assetClasses: action.assetClasses,
      };
    case types.default.SET_GUESSED_ASSET_CLASS:
      return {
        ...state,
        guessedAssetClasses: action.guessedAssetClasses,
      };
    case types.default.SET_ESTIMATOR_WORST_VALUE:
      return {
        ...state,
        estimatorWorstValue: action.estimatorWorstValue,
      };
    case types.default.SET_ESTIMATOR_BEST_VALUE:
      return {
        ...state,
        estimatorBestValue: action.estimatorBestValue,
      };
    case types.default.SET_ESTIMATOR_BEST_VISIT:
      return {
        ...state,
        estimatorBestVisit: action.estimatorBestVisit,
      };
    case types.default.SET_ESTIMATOR_WORST_VISIT: {
      return {
        ...state,
        estimatorWorstVisit: action.estimatorWorstVisit,
      };
    }

    case types.default.SET_ASSET_INSTRUMENT:
      return {
        ...state,
        assetInstruments: action.assetInstruments,
      };
    default:
      return state;
  }
};
