import React from "react";
import { Typography, Grid } from "@material-ui/core";
import styles from "./header.style";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";

const Header = ({ classes, title, showMenu }) => {
  const history = useHistory();
  const location = useLocation();
  const Logout = () => {
    history.push("/login");
  };
  return (
    <Grid container alignItems="center" className={classes.outerHeader}>
      <Grid item>
        <Typography className={classes.title}>AssetSathi</Typography>
      </Grid>
      {showMenu && (
        <Grid item className="flex justify-end gap-5">
          <Grid
            onClick={() => history.push("/dashboard")}
            className={`${
              location.pathname === "/dashboard" && "text-primary-700"
            } cursor-pointer font-semibold`}
          >
            Dashboard
          </Grid>
          <Grid
            onClick={() => history.push("/profile")}
            className={`${
              location.pathname === "/profile" && "text-primary-700"
            } cursor-pointer font-semibold`}
          >
            Your Profile
          </Grid>
          <Grid onClick={Logout} className="cursor-pointer font-semibold">
            Logout
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default withRouter(withStyles(styles)(Header));
