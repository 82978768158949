// eslint-disable-next-line import/no-anonymous-default-export
export default {
  cardList: {
    display: "grid",
    gridGap: "20px",
    "@media(max-width: 768px)": {
      gridTemplateColumns: "repeat(2,1fr)",
    },
    "@media(min-width: 960px)": {
      gridTemplateColumns: "repeat(3,1fr)",
    },
    "@media(min-width: 1200px)": {
      gridTemplateColumns: "repeat(4,1fr)",
    },
  },
  modalTitle: {
    marginBottom: 0,
    fontWeight: 400,
    fontSize: 18,
    borderBottom: "0.5px solid #c6c6c6",
    padding: 10,
    fontFamily: "Nunito Sans",
  },
  checkbokTitle: {
    "&:span": {
      fontFamily: "Nunito Sans",
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    border: "1.5px solid #E6E6E6",
    position: "relative",
    width: 150,
    alignItems: "flex-start",
    height: 100,
    borderRadius: "5px",
    padding: "15px",
    cursor: "pointer",
    margin: "5px",
    boxShadow: "4px 9px 1px 0px #E6E6E6",
    transition: "transform 0.25s ease-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  titleStyle: {
    marginLeft: 10,
    marginBottom: 10,
    fontWeight: 400,
    fontSize: 25,
  },
  subtitleStyle: {
    marginLeft: 10,
    color: "#37394A",
    lineHeight: "24px",
    fontWeight: 300,
    fontSize: 18,
    margin: 20,
  },
  countContainer: {
    "@media(max-width: 920px)": {
      flexDirection: "column",
      gap: 10,
      alignItems: "flex-end",
    },
  },
  clearAll: {
    color: "#6250DE",
    cursor: "pointer",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "20px",
    marginLeft: "8px",
    fontFamily: "Nunito Sans",
  },
  cardTitle: {
    color: "#37394A",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "18px",
  },
  cardSubtitle: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: 14,
    lineHeight: "40px",
    color: "#737578",
    textTransform: "capitalize",
  },
  iconPosition: {
    position: "absolute",
    bottom: -5,
    right: 0,
  },
  modalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    maxWidth: 600,
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    padding: 16,
    background: "#fff",
  },
};
