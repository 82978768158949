// eslint-disable-next-line import/no-anonymous-default-export
export default {
  centerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60vh",
    width: "100vw",
  },
  leftSection: {
    "@media(max-width: 920px)": {
      display: "none",
    },
  },
  stepZeroContainer: {
    padding: 50,
    background: "#f6f5f7",
    borderRadius: 8,
    boxShadow: "3px 4px 29px -7px rgba(108, 99, 255, 0.72)",
  },
  stepZeroCta: {
    background: "#49287d",
    color: "#fff",
    fontFamily: "Nunito Sans",
    margin: "0px auto",
    // fontStyle: "normal",
    fontWeight: "500",
    // fontSize: 18,
    // lineHeight: "24px",
    // padding: "20px",
    // transition: "transform 0.25s ease-out",
    // marginBottom: 15,
    "&:hover": {
      background: "#4a378a",
    },
  },
  titleStyle: {
    marginLeft: 10,
    marginBottom: 20,
    fontWeight: 400,
    fontSize: 25,
  },
  landingPageContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    alignItems: "flex-start",
    "@media(max-width: 768px)": {
      flexDirection: "column-reverse",
    },
  },
  cardList: {
    display: "grid",
    gridGap: "20px",
    "@media(min-width: 768px)": {
      gridTemplateColumns: "repeat(2,1fr)",
    },
    "@media(min-width: 960px)": {
      gridTemplateColumns: "repeat(2,1fr)",
    },
    "@media(min-width: 1200px)": {
      gridTemplateColumns: "repeat(2,1fr)",
    },
  },
  cta: {
    background: "#49287d",
    color: "#fff",
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: "24px",
    margin: 36,
    padding: "16px",
    transition: "transform 0.25s ease-out",
    marginBottom: 15,
    "&:hover": {
      background: "#4a378a",
      transform: "scale(1.05)",
    },
  },
  transferEstimatorContainer: {
    position: "relative",
    minWidth: 220,
    alignItems: "flex-start",
    margin: "20px 10px",
  },
  estimatorStatsContainer: {
    background: "rgba(200, 194, 237, 0.2)",
    padding: "20px",
    borderRadius: 8,
    margin: 20,
    marginLeft: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    border: "1px solid  #6250DE26",
  },
  transferEstimatorTitle: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 30,
    textTransform: "uppercase",
    lineHeight: "30px",
    color: "#37394A",
    display: "flex",
    justifyContent: "space-between",
  },
  transferEstimatorSubtitle: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 20,
    lineHeight: "40px",
    color: "#37394A",
  },
};
