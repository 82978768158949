// eslint-disable-next-line import/no-anonymous-default-export
export default {
  SET_ASSET_CLASS: "SET_ASSET_CLASS",
  SET_ASSET_INSTRUMENT: "SET_ASSET_INSTRUMENT",
  SET_ESTIMATOR_WORST_VALUE: "SET_ESTIMATOR_WORST_VALUE",
  SET_ESTIMATOR_BEST_VALUE: "SET_ESTIMATOR_BEST_VALUE",
  SET_GUESSED_ASSET_CLASS: "SET_GUESSED_ASSET_CLASS",
  SET_ESTIMATOR_WORST_VISIT: "SET_ESTIMATOR_WORST_VISIT",
  SET_ESTIMATOR_BEST_VISIT: "SET_ESTIMATOR_BEST_VISIT",
};
