import * as React from "react";
// import CircularProgress from '@mui/material/CircularProgress';
import { Box, CircularProgress } from "@material-ui/core";

const Spinner = ({ text }) => {
  return (
    <Box
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        alignItems: "center",
        gap: 10,
      }}
    >
      <CircularProgress
        style={{
          color: "#49287d",
        }}
        size={26}
      />
      <p style={{ fontSize: 22, margin: 0, marginTop: 2 }}>{text}</p>
    </Box>
  );
};

export default Spinner;
