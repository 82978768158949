// eslint-disable-next-line import/no-anonymous-default-export
export default {
  modalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    background: "#fff",
  },
  centerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
  },
  loginTitle: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 25,
    lineHeight: "20px",
    color: "#2a3236",
    textTransform: "capitalize",
  },
  subtitle: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: 14,
    lineHeight: "40px",
    color: "#737578",
    textTransform: "capitalize",
  },
  loginBox: {
    // padding: 50,
    // background: "#f6f5f7",

    padding: 20,
    borderRadius: 8,
    boxShadow: "3px 4px 29px -7px rgba(108, 99, 255, 0.72)",
  },
  labelName: {
    fontSize: 14,
    fontFamily: "Nunito Sans",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "20px",
    color: "#536471",
    margin: "5px 0",
  },
  cta: {
    background: "#49287d",
    color: "#fff",
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    lineHeight: "25px",
    transition: "transform 0.25s ease-out",
    "&:hover": {
      background: "#4a378a",
    },
  },
};
