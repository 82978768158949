import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#49287d",
    },
    secondary: {
      main: "#2196f3",
    },
    background: {
      default: "#f5f7fa",
    },
    text: {
      primary: "rgba(0,0,0,0.87)",
      secondary: "rgba(70,68,68,0.54)",
    },
    divider: "#aaaaaa",
  },
  typography: {
    fontFamily: "Nunito",
    fontSize: 14,
    fontWeightRegular: 400,
    h1: {
      fontSize: 24,
      fontWeight: 500,
    },
    h2: {
      fontSize: 10,
      fontWeight: 100,
    },
  },
});
