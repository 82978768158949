import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Button,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { allRequestHandler } from "../../api";
import { URLS } from "../../constants/index";
import Multiselect from "multiselect-react-dropdown";
import { Link, useHistory } from "react-router-dom";
import styles from "../Dashboard/dashboard.style";
import Dropdown from "./Dropdown";
const BulkInsert = ({ classes }) => {
  const history = useHistory();
  const [assetInstituition, setAssetInstituition] = useState([]);
  const [addInstitution, setAddInstituition] = useState([]);
  const [selectedInstituition, setSelectedInstituition] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(async () => {
    let getAssetInstituitions = await allRequestHandler({
      requestType: "GET",

      requestUrl: URLS.INSTITUITION,
    });

    setAssetInstituition(getAssetInstituitions);
    setRows(
      selectedAssetInstrument.map((item) => {
        return {
          id: Math.random(),
          class_id: item.assetclasses.id,
          class: item.assetclasses.name,
          instrument_id: item.id,
          instrument_name: item.name,
          institution: "",
          addedInstituition: [],
        };
      })
    );
  }, []);
  const addHouseholdAsset = async () => {
    let h;
    // for (let i = 0; i < rows.length; i++) {
    //   let ins = [...rows[i].institution, ...rows[i].addedInstituition];
    //   for (let j = 0; j < ins.length; j++) {
    //     h.push({
    //       "instrumentname.id": rows[i].instrument_id,
    //       "instrumentname.name": rows[i].instrument_name,
    //       "institution.id": ins[j].id ?? null,
    //       "institution.name": ins[j].name ?? ins[j],
    //       "assetclass.id": rows[i].class_id,
    //       desc: "",
    //       location: "",
    //       metadata: null,
    //     });
    //   }
    // }
    console.log(rows);
    h = rows.map((item) => {
      return {
        "instrumentname.id": item.instrument_id,
        "instrumentname.name": item.instrument_name,
        "institution.id": item.institution?.id,
        "institution.name": item.institution?.name,
        "assetclass.id": item.class_id,
        desc: "",
        location: "",
        metadata: null,
      };
    });
    let addAsset = await allRequestHandler({
      requestType: "POST",
      requestUrl: URLS.HOUSEHOLDASSETS,
      requestData: h,
    });
    console.log(addAsset);
    if (addAsset.message == "Asset Information Added Successfully!")
      history.push("/dashboard");
  };

  const filteredInstitutions =
    assetInstituition?.filter((u) =>
      selectedInstituition?.includes(u?.id ?? "")
    ) ?? [];

  const selectedAssetInstrument = useSelector(
    (state) => state.assetInstruments
  );

  return (
    <div className="w-full p-8">
      <div className="text-2xl font-bold mx-4  text-primary-800">
        Add your assets
      </div>
      <div className="border border-sm rounded border-primary-700 m-4 w-full">
        <Table className="!border-0 !rounded-t-lg !flex !flex-col !h-full mt-6">
          <TableHead className="w-full !flex !rounded-t-lg border-b-sm">
            <TableCell className="w-1/4  !border-b-0 text-center !flex !items-center !justify-center">
              <div className="border-b-primary-700 border-b-0 uppercase text-primary-700 text-base font-semibold pb-[11px]">
                {" "}
                Asset class
              </div>
            </TableCell>
            <TableCell className="w-1/4  !border-b-0 text-center !flex !items-center !justify-start">
              <div className="border-b-primary-700 border-b-0 uppercase text-primary-700 text-base font-semibold pb-[11px]">
                Asset instrument
              </div>
            </TableCell>
            <TableCell className="w-2/4  !border-b-0 text-center !flex !items-center !justify-start">
              <div className="border-b-primary-700 border-b-0 uppercase text-primary-700 text-base font-semibold pb-[11px]">
                Select Asset Institution
              </div>
            </TableCell>
            {/* <TableCell className="w-2/4  !border-b-0 text-center !flex !items-center !justify-center">
            Add Asset Institution
          </TableCell> */}
          </TableHead>
          <TableBody>
            {rows.map((item, idx) => {
              return (
                <TableRow
                  key={idx}
                  className="w-full !flex !border-0 !h-[100px]"
                >
                  <TableCell className="!flex  !border-b-sm !p-3  !h-full !justify-center !items-center !w-1/4">
                    <div className="w-[150px] text-sm font-normal text-dark-blue">
                      {" "}
                      {item.class}
                    </div>
                  </TableCell>
                  <TableCell className="!flex  !border-b-sm !p-3  !h-full !justify-center !items-center !w-1/4">
                    <div className="flex w-full text-dark-blue text-sm font-semibold">
                      {" "}
                      {item.instrument_name}
                    </div>
                  </TableCell>
                  <TableCell className=" !border-b-sm !p-3  !h-full !justify-center !items-center !w-2/4">
                    {/* <Autocomplete
                    value={item.institution}
                    limitTags={5}
                    multiple
                    id="size-small-outlined-multi"
                    className={`flex-grow overflow-hidden !outline-0 !disabled:opacity-30 `}
                    size="small"
                    options={assetInstituition}
                    disableCloseOnSelect
                    onChange={(e, value) => {
                      item.institution = [...item.institution, ...value];
                      console.log(value);
                    }}
                    getOptionLabel={(option) => option?.name ?? ""}
                    renderInput={(params) => (
                      <div>
                        <TextField
                          {...params}
                          placeholder="Select Instituitions"
                          variant="outlined"
                          label=""
                          size="small"
                          className=""
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              width: "100%",
                              legend: {
                                marginLeft: "30px",
                              },
                            },

                            "& .MuiInputLabel-shrink": {
                              height: "40px",
                              marginLeft: "20px",
                              paddingLeft: "10px",
                              paddingRight: 0,
                              background: "white",
                            },
                          }}
                        />
                      </div>
                    )}
                    sx={{
                      ".MuiInputBase-input": {
                        padding: "2.5px 12px !important",
                      },
                      ".MuiAutocomplete-root fieldset:hover": {
                        borderColor: "#2196F3 !important",
                      },
                    }}
                    renderOption={(props, option, state) => {
                      return (
                        props.name && (
                          <li
                            className={`m-[6px] py-[10px] text-gray-700 text-sm font-medium pr-[10px] ${
                              state?.selected ? "bg-gray-50" : ""
                            }`}
                          >
                            {state?.selected && (
                              <div className="flex w-full justify-between">
                                {props.name}
                              </div>
                            )}
                            {!state?.selected && <div>{props.name}</div>}
                          </li>
                        )
                      );
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        // eslint-disable-next-line react/jsx-key
                        <Chip
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                          className="!rounded-sm !bg-transparent !border-gray-300 !border-sm !border-solid !border-1 !mr-[4px]"
                        />
                      ))
                    }
                  /> */}

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={item?.institution?.name}
                      onChange={(e) => {
                        item.institution = e.target.value;
                      }}
                      label="Select Asset Instituition"
                      classes={{
                        root: classes.searchByInput,
                      }}
                      style={{ width: "100%" }}
                    >
                      {assetInstituition?.map((item) => (
                        <MenuItem
                          value={item}
                          classes={{
                            root: classes.searchByInput,
                          }}
                        >
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>

                  {/* <TableCell className="!border !border-b-sm !p-3 !pt-5 !border-r-sm !border-l-sm !h-full !justify-center !items-center !w-2/4">
                  <Autocomplete
                    multiple
                    freeSolo
                    id="tags-outlined"
                    ListboxProps={{ style: { height: 50 } }}
                    options={[]}
                    defaultValue={item.addedInstituition}
                    onChange={(e) =>
                      (item.addedInstituition = [
                        ...item.addedInstituition,
                        e.target.value,
                      ])
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label=""
                        size={"small"}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            width: "100%",
                            legend: {
                              marginLeft: "30px",
                            },
                          },

                          "& .MuiInputLabel-shrink": {
                            height: "40px",
                            marginLeft: "20px",
                            paddingLeft: "10px",
                            paddingRight: 0,
                            background: "white",
                          },
                        }}
                        placeholder="Type and press enter to add an instituition"
                      />
                    )}
                    sx={{
                      ".MuiInputBase-input": {
                        padding: "2.5px 12px !important",
                      },
                      ".MuiAutocomplete-root fieldset:hover": {
                        borderColor: "#2196F3 !important",
                      },
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        // eslint-disable-next-line react/jsx-key
                        <Chip
                          label={option}
                          size="small"
                          // onDelete={(val) => console.log(val.target.value, 'deleted')}
                          // deleteIcon={
                          // <CloseTag
                          // onMouseDown={(val) => console.log(val.target.value, 'deleted')}
                          // />
                          // }
                          className="!rounded-sm !bg-transparent !border-gray-300 !border-sm !border-solid !mr-[4px]"
                        />
                      ))
                    }
                  />
                </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className="py-4 flex justify-center items-center">
        <Button
          variant="outlined"
          className="!bg-primary-700 !text-white !font-medium"
          disableElevation
          onClick={addHouseholdAsset}
        >
          Add Assets
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(BulkInsert);
