export default {
  outerHeader: {
    height: 62,
    borderBottom: "0.5px solid #c5c5c5",
    background: "#fff",
    padding: "0 20px",
    width: "100%",
    justifyContent: "space-between",
    margin: "0 auto",
    boxShadow: `inset 0 0 15px rgba(55, 84, 170,0),
      inset 0 0 20px rgba(255, 255, 255,0),
      7px 7px 15px rgba(55, 84, 170,.15),
      -7px -7px 20px rgba(255, 255, 255,1),
      inset 0px 0px 4px rgba(255, 255, 255,.2)`,
  },

  title: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: 24,
    lineHeight: "40px",
    color: "#08001D",
    textTransform: "capitalize",
  },
  plantLocation: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    color: "#536471",
    marginLeft: 8,
  },
  initialName: {
    width: 42,
    height: 42,
    background: "#F0F4FA",
    borderRadius: 72,
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: "18px",
    color: "#26184D",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  menuOuter: {
    width: 200,
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    boxSizing: "border-box",
    boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
    borderRadius: 8,
    marginTop: 40,
  },
  menuItem: {
    height: 32,
    padding: "6px 16px",
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    color: "#536471",
  },
  menuProfile: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "24px",
    color: "#536471",
  },
  menuEmail: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "20px",
    color: "#8C9AA4",
  },
  menuInner: {
    height: 60,
    padding: "0 16px",
    borderBottom: "1px solid #E4EAF5",
    marginBottom: 8,
  },
  profileTitle: {
    color: "#536471",
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: 600,
  },
};
