import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { allRequestHandler } from "../../api";
import { URLS } from "../../constants/index";
import CustomTextField from "../customStyle/textfield";
import { Table, TableRow, TableCell, Button } from "@mui/material";
import Back from "../../assets/back.svg";
import { useHistory } from "react-router-dom";

const Profile = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [editState, setEditState] = useState(false);
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState("");

  useEffect(async () => {
    let getProfile = await allRequestHandler({
      requestType: "GET",
      requestUrl: URLS.REGISTER,
    });
    console.log(getProfile, "fefe");
    if (getProfile) {
      setName(getProfile[0]?.name);
      setCity(getProfile[0]?.city);
      setState(getProfile[0]?.state);
      setAddress(getProfile[0]?.address);
      setDob(getProfile[0]?.dob);
      setLoading(false);
    }
  }, []);

  const registerUser = async () => {
    let getToken = await allRequestHandler({
      requestType: "POST",
      requestUrl: URLS.REGISTER,
      requestData: {
        city: city,
        state: state,
        dob: dob,
        address: address,
        name: name,
      },
    });
    console.log(getToken);
    if (getToken.message == "profile registered/updated successfully!") {
      setEditState(false);
    }
  };

  return (
    <>
      <Header showMenu={true} />
      <div className="h-full w-full flex justify-center items-center p-4 pl-8 pt-8 max-w-[60%] my-0 mx-auto mt-4">
        <div className="w-full">
          <div className="flex justify-between">
            <div className="text-lg uppercase text-gray-800 font-semibold flex items-center">
              <span
                style={{
                  marginRight: 8,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => setEditState(false)}
              >
                <img src={Back} alt="back" />
              </span>
              Your Profile
            </div>
            {!editState && (
              <Button
                variant="outlined"
                className="!bg-primary-700 !text-white !font-medium"
                disableElevation
                onClick={() => setEditState(!editState)}
              >
                Edit
              </Button>
            )}
          </div>
          {editState ? (
            <>
              <div className="my-4">
                <div>Enter Name</div>

                <CustomTextField
                  id="name"
                  name="name"
                  autoComplete="off"
                  autoFocus
                  value={name}
                  handleChange={(e) => setName(e.target.value)}
                  placeholder="Enter Name"
                  BoxHeight={"38px"}
                />
              </div>
              <div className="my-4">
                <div>Enter City</div>

                <CustomTextField
                  id="city"
                  name="city"
                  autoComplete="off"
                  autoFocus
                  value={city}
                  handleChange={(e) => setCity(e.target.value)}
                  placeholder="Enter City"
                  BoxHeight={"38px"}
                />
              </div>
              <div className="my-4">
                <div>Enter State</div>

                <CustomTextField
                  id="state"
                  name="state"
                  autoComplete="off"
                  autoFocus
                  value={state}
                  handleChange={(e) => setState(e.target.value)}
                  placeholder="Enter State"
                  BoxHeight={"38px"}
                />
              </div>
              <div className="my-4">
                <div>Enter Address</div>

                <CustomTextField
                  id="address"
                  name="address"
                  autoComplete="off"
                  autoFocus
                  value={address}
                  handleChange={(e) => setAddress(e.target.value)}
                  placeholder="Enter Address"
                  BoxHeight={"38px"}
                />
              </div>
              <div className="my-4">
                <div>Enter Date of Birth</div>

                <CustomTextField
                  id="dob"
                  name="dob"
                  autoComplete="off"
                  autoFocus
                  type={"date"}
                  value={dob}
                  handleChange={(e) => setDob(e.target.value)}
                  placeholder="Enter Date of Birth"
                  BoxHeight={"38px"}
                />
              </div>
            </>
          ) : (
            <div>
              <Table className="!border-0 !rounded-t-lg !flex !flex-col !h-full mt-6">
                <TableRow className="w-full !flex !border-0 !h-[50px]">
                  <TableCell className="!flex  !border-b-sm !p-3  !h-full !justify-center !items-center !w-1/2">
                    <div className="w-[150px] text-sm font-normal text-primary-800 font-semibold">
                      Name
                    </div>
                  </TableCell>
                  <TableCell className="!flex  !border-b-sm !p-3  !h-full !justify-center !items-center !w-1/2">
                    <div className="w-[150px] text-sm font-normal text-dark-blue">
                      {" "}
                      {name}
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow className="w-full !flex !border-0 !h-[50px]">
                  <TableCell className="!flex  !border-b-sm !p-3  !h-full !justify-center !items-center !w-1/2">
                    <div className="w-[150px] text-sm font-normal text-primary-800 font-semibold">
                      City
                    </div>
                  </TableCell>
                  <TableCell className="!flex  !border-b-sm !p-3  !h-full !justify-center !items-center !w-1/2">
                    <div className="w-[150px] text-sm font-normal text-dark-blue">
                      {" "}
                      {city}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow className="w-full !flex !border-0 !h-[50px]">
                  <TableCell className="!flex  !border-b-sm !p-3  !h-full !justify-center !items-center !w-1/2">
                    <div className="w-[150px] text-sm font-normal text-primary-800 font-semibold">
                      State
                    </div>
                  </TableCell>
                  <TableCell className="!flex  !border-b-sm !p-3  !h-full !justify-center !items-center !w-1/2">
                    <div className="w-[150px] text-sm font-normal text-dark-blue">
                      {" "}
                      {state}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow className="w-full !flex !border-0 !h-[50px]">
                  <TableCell className="!flex  !border-b-sm !p-3  !h-full !justify-center !items-center !w-1/2">
                    <div className="w-[150px] text-sm font-normal text-primary-800 font-semibold">
                      Address
                    </div>
                  </TableCell>
                  <TableCell className="!flex  !border-b-sm !p-3  !h-full !justify-center !items-center !w-1/2">
                    <div className="w-[150px] text-sm font-normal text-dark-blue">
                      {" "}
                      {address}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow className="w-full !flex !border-0 !h-[50px]">
                  <TableCell className="!flex  !border-b-sm !p-3  !h-full !justify-center !items-center !w-1/2">
                    <div className="w-[150px] text-sm font-normal text-primary-800 font-semibold">
                      Date of Birth
                    </div>
                  </TableCell>
                  <TableCell className="!flex  !border-b-sm !p-3  !h-full !justify-center !items-center !w-1/2">
                    <div className="w-[150px] text-sm font-normal text-dark-blue">
                      {" "}
                      {dob}
                    </div>
                  </TableCell>
                </TableRow>
              </Table>
            </div>
          )}

          {editState && (
            <div className="my-4 flex justify-center min-w-[200px]">
              <Button
                variant="outlined"
                className="!bg-primary-700 !text-white !font-medium"
                disableElevation
                onClick={registerUser}
              >
                Update Profile
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Profile;
