import * as types from "./types";

const setAssetClass = (assetClasses) => ({
  type: types.default.SET_ASSET_CLASS,
  assetClasses,
});

const setGuessedAssetClass = (guessedAssetClasses) => ({
  type: types.default.SET_GUESSED_ASSET_CLASS,
  guessedAssetClasses,
});

const setAssetClassInstruments = (assetInstruments) => ({
  type: types.default.SET_ASSET_INSTRUMENT,
  assetInstruments,
});

const setEstimatorWorstValue = (estimatorWorstValue) => ({
  type: types.default.SET_ESTIMATOR_WORST_VALUE,
  estimatorWorstValue,
});

const setEstimatorBestValue = (estimatorBestValue) => ({
  type: types.default.SET_ESTIMATOR_BEST_VALUE,
  estimatorBestValue,
});

const setEstimatorWorstVisit = (estimatorWorstVisit) => ({
  type: types.default.SET_ESTIMATOR_WORST_VISIT,
  estimatorWorstVisit,
});

const setEstimatorBestVisit = (estimatorBestVisit) => ({
  type: types.default.SET_ESTIMATOR_BEST_VISIT,
  estimatorBestVisit,
});

function getEstimatorValue() {
  return async (dispatch, getState) => {
    let worstTimeLines = [];
    let idealTimeLines = [];
    let worstVisits = [];
    let courtVisits = [];
    let bestVisits = [];
    let worst, best, worstVisit, bestVisit, courtVisit;

    getState().mainAssetClasses.map((item) => {
      if (getState().assetClasses.includes(item.id)) {
        worstTimeLines.push(item.worst_time);
        idealTimeLines.push(item.ideal_time);
        worstVisits.push(item.worst_visits);
        bestVisits.push(item.ideal_visits);
        courtVisits.push(item.court_visits);
      }
    });

    worstTimeLines = worstTimeLines.filter(
      (item, index) => worstTimeLines.indexOf(item) === index
    );

    idealTimeLines = idealTimeLines.filter(
      (item, index) => idealTimeLines.indexOf(item) === index
    );
    worstVisits = worstVisits.filter(
      (item, index) => worstVisits.indexOf(item) === index
    );

    bestVisits = bestVisits.filter(
      (item, index) => bestVisits.indexOf(item) === index
    );

    courtVisits = courtVisits.filter(
      (item, index) => courtVisits.indexOf(item) === index
    );

    worst = worstTimeLines.sort((a, b) => a - b).reverse()[0];
    best = idealTimeLines.sort((a, b) => a - b).reverse()[0];
    worstVisit = worstVisits.sort((a, b) => a - b).reverse()[0];
    bestVisit = bestVisits.sort((a, b) => a - b).reverse()[0];
    courtVisit = courtVisits.sort((a, b) => a - b).reverse()[0];

    dispatch(setEstimatorWorstValue(worst));
    dispatch(setEstimatorBestValue(best));
    dispatch(setEstimatorWorstVisit(worstVisit + courtVisit));
    dispatch(setEstimatorBestVisit(bestVisit));
  };
}
function setAssetClasses(asset) {
  return async (dispatch, getState) => {
    let updatedAssetClass;
    if (getState().assetClasses.includes(asset)) {
      updatedAssetClass = getState().assetClasses.filter(
        (item) => item !== asset
      );
    } else updatedAssetClass = [...getState().assetClasses, asset];

    dispatch(setAssetClass(updatedAssetClass));
  };
}

function manipulateInstruments(instruments) {

  return async (dispatch, getState) => {
    let updatedAssetIns;
    if (getState().assetInstruments.includes(instruments)) {
      updatedAssetIns = getState().assetInstruments.filter(
        (item) => item !== instruments
      );
    } else updatedAssetIns = [...getState().assetInstruments, instruments];
console.log(updatedAssetIns)
    dispatch(setAssetClassInstruments(updatedAssetIns));
  };
}
export {
  manipulateInstruments,
  getEstimatorValue,
  setAssetClass,
  setEstimatorWorstValue,
  setEstimatorBestValue,
  setEstimatorWorstVisit,
  setEstimatorBestVisit,
  setAssetClasses,
  setAssetClassInstruments,
  setGuessedAssetClass,
};
