import React, { useState } from "react";
import Header from "../../components/header";
import CardList from "../../components/cardList";
import { withStyles } from "@material-ui/core/styles";
import styles from "./landingPage.style";
import { Grid, Typography, Button, Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import CustomTextField from "../customStyle/textfield";
import { useDispatch, useSelector } from "react-redux";
import Illustration from "../../assets/undraw_thought_process_re_om58.svg";
import Back from "../../assets/back.svg";
import Spinner from "../../views/customStyle/spinner";
import { setGuessedAssetClass } from "../../store/actions";


const LandingPage = ({ classes }) => {
  const dispatch = useDispatch();
  const best = useSelector((state) => state.estimatorBestValue) || 0;
  const worst = useSelector((state) => state.estimatorWorstValue) || 0;
  const bestVisit = useSelector((state) => state.estimatorBestVisit) || 0;
  const worstVisit = useSelector((state) => state.estimatorWorstVisit) || 0;
  const [step, setStep] = useState(0
  );
  const [assetClassNumber, setAssetClassNumber] = useState("");

  const info = [
    "80% people forget 2-3 assets they own",
    "Average Indian investor has 3-4 term insurances",
    "Courts of India can take upto 3% of the entire estate to give a successful conflict",
  ];
  return (
    <>
      <Header />

      {step === 0 && (
        <Grid className={classes.centerContainer} style={{ gap: 60 }}>
          <Grid className={classes.leftSection}>
            <img src={Illustration} height={600} width={600} />
          </Grid>
          <Grid className={classes.stepZeroContainer}>
            <Typography
              style={{
                marginBottom: 24,
                fontSize: 20,
                fontWeight: 400,
                lineHeight: "21px",
                fontFamily: "Nunito Sans",
              }}
            >
              Can you recollect how many types of assets do you own?
            </Typography>
            <Grid style={{ marginBottom: 16 }}>
              <CustomTextField
                id="assetClass"
                name="asset-class"
                autoComplete="off"
                autoFocus
                style={{ height: "48px", fontSize: 16, borderRadius: 8 }}
                placeholder="Enter your asset number"
                handleChange={(e) => setAssetClassNumber(e.target.value)}
              />
            </Grid>

            <Button
              variant="contained"
              className={classes.stepZeroCta}
              disableElevation
              onClick={() => {
                setStep(1);
                dispatch(setGuessedAssetClass(assetClassNumber));
                setTimeout(() => setStep(2), 3000);
              }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      )}
      {step == 1 && (
        <>
          <Spinner text={info[1]} />
        </>
      )}
      {step == 2 && (
        <Grid style={{ margin: "0 auto" }}>
          <CardList />
          <Grid style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                marginRight: 8,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => setStep(0)}
            >
              <img src={Back} alt="back" />
              <span style={{ marginLeft: 4 }}> Back</span>
            </span>
            <Button
              variant="contained"
              className={classes.cta}
              disableElevation
              onClick={() => {
                setStep(3);
                setTimeout(() => setStep(4), 3000);
              }}
              style={{ marginTop: 16 }}
            >
              See the effort required to get these transferred
            </Button>
          </Grid>
        </Grid>
      )}
      {step == 3 && (
        <>
          <Spinner text={info[2]} />
        </>
      )}
      {step === 4 && (
        <Grid className={classes.landingPageContainer}>
          <Grid className={classes.transferEstimatorContainer}>
            <h2 className={classes.titleStyle} style={{ margin: 0 }}>
              Effort estimate to transfer your assets
            </h2>

            <Grid>
              <p>It could take your dependants</p>
              <Divider />
              <Grid className={classes.estimatorStatsContainer}>
                <Grid className={classes.transferEstimatorTitle}>
                  <Grid>
                    <span style={{ color: "#198754" }}>
                      <b>{best}</b> months
                    </span>
                    <div style={{ fontSize: 14 }}>(ideal case)</div>
                  </Grid>
                  {"     "}-{"     "}
                  <Grid>
                    <span style={{ color: "#dc3545" }}>
                      <b>{worst}</b> months
                    </span>
                    <div style={{ fontSize: 14 }}>(worst case)</div>
                  </Grid>
                </Grid>
                <Typography className={classes.transferEstimatorSubtitle}>
                  Number of months
                </Typography>
              </Grid>

              <Grid className={classes.estimatorStatsContainer}>
                <Grid className={classes.transferEstimatorTitle}>
                  <Grid>
                    <span style={{ color: "#198754" }}>
                      <b>{bestVisit}</b> visits
                    </span>
                    <div style={{ fontSize: 14 }}>(ideal case)</div>
                  </Grid>
                  {"     "}-{"     "}
                  <Grid>
                    <span style={{ color: "#dc3545" }}>
                      <b>{worstVisit}</b> visits
                    </span>
                    <div style={{ fontSize: 14 }}>(worst case)</div>
                  </Grid>
                </Grid>
                <Typography className={classes.transferEstimatorSubtitle}>
                  Number of Institutions/Court visits
                </Typography>
              </Grid>
              <Grid className={classes.estimatorStatsContainer}>
                <Grid
                  style={{
                    display: "flex",
                    gap: 40,
                    justifyContent: "center",
                    padding: 15,
                  }}
                >
                  <Grid>
                    <span style={{ color: "#198754", fontSize: 30 }}>
                      <b>INR 15000</b>
                    </span>
                    <div style={{ fontSize: 14 }}>(ideal)</div>
                  </Grid>
                  {"     "}-{"     "}
                  <Grid>
                    <span
                      style={{
                        color: "#dc3545",
                        fontSize: 15,
                      }}
                    >
                      <b>
                        <span style={{ fontSize: 30 }}>2-3% </span>
                      </b>
                      of total worth of transferable assets
                    </span>
                    <div style={{ fontSize: 14 }}>(worst)</div>
                  </Grid>
                </Grid>
                <Typography className={classes.transferEstimatorSubtitle}>
                  Cost Estimate
                </Typography>
              </Grid>
              <Divider />
              <p>
                to get everything transferred to their name based on the health
                of your assets
              </p>
            </Grid>
            <Grid style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  margin: 16,
                  marginTop: 50,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => setStep(2)}
              >
                <img src={Back} alt="back" />
                <span style={{ marginLeft: 4 }}> Back</span>
              </span>
              <Grid
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <Link to="/login">
                  <Button
                    variant="contained"
                    className={classes.cta}
                    style={{ margin: 0, marginTop: 36 }}
                    disableElevation
                  >
                    Consolidate your assets and evaluate their health
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withStyles(styles)(LandingPage);
