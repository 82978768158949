// const requestUrl = "https://devapi.assetsathi.in/";
// const requestUrl = "https://mvpv0-2tkbj4wc7a-el.a.run.app/";

const requestUrl = "https://prod-mvpv0-2tkbj4wc7a-el.a.run.app/";
export const URLS = {
  //landing page
  ASSET_CLASS: requestUrl + "assetclasses",
  ASSET_INSTRUMENT: requestUrl + "instrumentnames",
  INSTITUITION: requestUrl + "institutions",

  //auth
  OTP: requestUrl + "login",
  VERIFYOTP: requestUrl + "verify",
  REGISTER: requestUrl + "profile",
  SIGNUP: requestUrl + "signup",

  //dashboard
  HOUSEHOLDASSETS: requestUrl + "householdassets",
  DEPENDANTS: requestUrl + "dependents",
};
