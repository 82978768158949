import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Login from "./views/Login";
import LandingPage from "./views/LandingPage";
import Dashboard from "./views/Dashboard";
import Profile from "./views/Profile";
import { ThemeProvider } from "@mui/styles";
import { theme } from "./theme";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";

const store = configureStore();

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <Switch>
              <Route exact path="/login" render={() => <Login />} />
              <Route exact path="/dashboard" render={() => <Dashboard />} />
              <Route exact path="/profile" render={() => <Profile />} />
              <Grid container style={{ height: "100vh" }}>
                <LandingPage />
              </Grid>
            </Switch>
          </Router>
        </Provider>
      </ThemeProvider>
    </>
  );
};

export default App;
